import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Corine = () => {
  return (
    <Layout>
      <Head title="Corine Ko" />
      <h3>Corine Ko</h3>
      <p>
        <OutboundLink href="https://www.paragongallery.co.uk/gallery/corine-ko">
          Paragon Gallery
        </OutboundLink>
      </p>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1659591749/artnow/corine%20ko/Corine-Ko-2.jpg"
          alt="Corine Ko art"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1659591749/artnow/corine%20ko/Corine-Ko-3.jpg"
          alt="Corine Ko art"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1659591749/artnow/corine%20ko/Corine-Ko-4.jpg"
          alt="Corine Ko art"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1659591749/artnow/corine%20ko/Corine-Ko-5.jpg"
          alt="Corine Ko art"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1659591749/artnow/corine%20ko/Corine-Ko-1.jpg"
          alt="Corine Ko art"
        />
      </div>
    </Layout>
  );
};

export default Corine;
